import { ApiClient } from "./clients";
import { Routine, ListService } from "@/types";

export const RoutineService: ListService<Routine> = {
  async all(): Promise<Array<any>> {
    const routines = await ApiClient.get(`/api/v1/routines/`);
    if (!routines || !routines.length) return [];
    return routines.map((r: any) => ({ ...r, updated_at: r.created_at }));
  },
  async load(id: number) {
    try {
      const routine = await ApiClient.get(`/api/v1/routine/${id}`);
      if (!routine) return null;
      const r = { ...routine, updated_at: routine.created_at };
      return r;
    } catch (e) {
      console.error(e);
    }
    return null;
  },
};
