<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useAutofocus } from "@/composables/useAutofocus";
import ColoredChip from "../ui/ColoredChip.vue";
import {
  EventTypeCardinalProperty,
  EventTypeOrdinalProperty,
  EventTypeZoneProperty,
  EventType,
  EventTypeProductProperty,
  EventTypeEquipmentProperty,
} from "@/types";
import { localize } from "@/utils/localize";

import { useFabriqStore } from "@/store/fabriq";
import { useEventFiltersStore } from "@/store/eventFilters";
import { storeToRefs } from "pinia";
import { useFields } from "@/composables/useFields";

interface Props {
  selectedValue?: number | string;
  eventTypeProperty:
    | EventTypeCardinalProperty
    | EventTypeOrdinalProperty
    | EventTypeZoneProperty
    | EventTypeProductProperty
    | EventTypeEquipmentProperty;
  eventType: EventType;
  label: string;
}
const fabriqStore = useFabriqStore();

const eventFiltersStore = useEventFiltersStore();

const { availableZones } = storeToRefs(eventFiltersStore);

const emit = defineEmits(["remove", "update", "done"]);
const props = defineProps<Props>();

const { t } = useI18n();
const valueFilter = ref("");

onMounted(() => useAutofocus(".filter-input input"));
const { locale } = fabriqStore;

const canRemoveProperty = computed(() => {
  return (
    props.eventTypeProperty.type !== "zone" &&
    props.eventTypeProperty.type !== "cardinal" &&
    props.eventTypeProperty.type !== "ordinal" &&
    props.eventTypeProperty.type !== "equipment" &&
    props.eventTypeProperty.type !== "product"
  );
});

const { organizationFields, availableEquipmentFields, availableProductFields } =
  useFields();

const selectedValueData = computed(() => {
  if (
    props.selectedValue === undefined ||
    props.selectedValue === "" ||
    props.selectedValue === null
  ) {
    return { label: "" };
  }
  switch (props.eventTypeProperty.type) {
    case "cardinal": {
      const property = props.eventTypeProperty.values.find(
        (v) => v.id === props.selectedValue
      );
      const label = property?.label;
      if (!label) {
        return { label: "" };
      }
      return {
        label: localize(label, locale, 0),
        emoji: property.emoji,
        color: property.color,
      };
    }
    case "ordinal": {
      const property = props.eventTypeProperty.values.find(
        (v) => v.id === props.selectedValue
      );
      const label = property?.label;
      if (!label) {
        return { label: "" };
      }
      return { label: localize(label, locale, 0), color: property.color };
    }
    case "zone": {
      const zone = availableZones.value.find((z) => {
        return z.id === Number(props.selectedValue);
      });
      if (!zone) {
        return { label: "" };
      }
      return { label: zone.name, color: zone.color };
    }
    case "equipment":
    case "product": {
      const field = organizationFields.value.get(props.selectedValue as number);
      const label = field?.name ? localize(field.name, locale, 0) ?? "" : "";
      return { label };
    }
    default:
      return { label: "" };
  }
});
const filteredValues = computed(() => {
  let fields: any[] = [];
  switch (props.eventTypeProperty.type) {
    case "cardinal":
      fields = props.eventTypeProperty.values.map((v) => ({
        id: v.id,
        value: v.id,
        label: localize(v.label, locale, 0),
        color: v.color,
        emoji: v.emoji,
      }));
      break;
    case "ordinal":
      fields = props.eventTypeProperty.values.map((v) => ({
        id: v.id,
        value: v.id,
        label: localize(v.label, locale, 0),
        color: v.color,
      }));
      break;
    case "zone":
      fields = eventFiltersStore
        .filteredZoneFromEventType(props.eventType)
        .map((z) => ({
          id: z.id,
          value: z.id,
          label: z.name,
          color: z.color,
        }));
      break;
    case "equipment": {
      fields = availableEquipmentFields.value.map((v) => ({
        id: v.id,
        value: v.id,
        label: localize(v.name, locale, 0),
      }));
      break;
    }
    case "product": {
      fields = availableProductFields.value.map((v) => ({
        id: v.id,
        value: v.id,
        label: localize(v.name, locale, 0),
      }));
      break;
    }
    default:
      fields = [];
  }

  if (!valueFilter.value) return fields;

  return fields.filter((f) => {
    return f.label.toLowerCase().includes(valueFilter.value.toLowerCase());
  });
});

const removeValue = () => {
  emit("remove", undefined);
};

const addValue = (value: number | string) => {
  emit("update", value);
};
</script>

<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar edit-modal-toolbar">
      <ion-title>{{ label }}</ion-title>
      <ion-buttons slot="end">
        <ion-button fill="clear" class="cancel-btn" @click="$emit('done')">
          {{ t("common.done") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <div
      class="modal-header"
      style="background-color: var(--ion-color-primary-contrast)"
    >
      <div
        class="ion-padding-horizontal ion-margin-horizontal"
        style="background-color: var(--ion-color-primary-contrast)"
      >
        <ion-input
          class="filter-input"
          :placeholder="label"
          v-model="valueFilter"
        />
        <div class="selected-values ion-margin-top">
          <colored-chip
            :clearable="canRemoveProperty"
            v-if="selectedValueData.label"
            :label="selectedValueData.label"
            :color="(selectedValueData.color as `#${string}` | undefined)"
            :emoji="selectedValueData.emoji"
            @remove="removeValue"
            noMaxWidth
          />
        </div>
      </div>
      <div class="list-title ion-margin-top">
        {{ label }}
      </div>
    </div>
  </ion-header>

  <ion-content :fullscreen="true" :scroll-y="false">
    <ion-list ref="list" class="scroller ion-padding-horizontal" lines="none">
      <recycle-scroller
        class="scroller"
        :items="filteredValues"
        :item-size="44"
      >
        <template #default="{ item }">
          <ion-item @click="addValue(item.value)">
            <colored-chip
              class="clickable"
              :label="item.label"
              :color="item.color"
              :emoji="item.emoji"
            />
          </ion-item>
        </template>
      </recycle-scroller>
    </ion-list>
  </ion-content>
</template>

<style scoped>
.selected-values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--ion-padding) / 2);
  max-height: 100px;
  overflow: auto;
}
.list-title {
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary-shade);
}
</style>
