<script lang="ts" setup>
import { IonText, modalController } from "@ionic/vue";
import { computed } from "vue";
import { useI18n } from "@/composables/useI18n";
import DateTimePickerModal from "@/components/modals/DateTimePickerModal.vue";
import { format } from "date-fns";
import { inputDateToIsoDate } from "@/utils/dates";
import { toastController } from "@ionic/vue";
import { getDateFnsLocales } from "@/i18n";
import { useFabriqStore } from "@/store/fabriq";

type Props = {
  readonly?: boolean;
  date: string;
};

const props = withDefaults(defineProps<Props>(), {
  readonly: false,
  date: "",
});
const emit = defineEmits(["modal", "date"]);
const { t } = useI18n();
const fabriqStore = useFabriqStore();
const formated = computed(() => {
  if (!props.date || !props.date.length) {
    return {
      date: t("datetime.emptyDueDate"),
      time: t("datetime.emptyDueDate"),
    };
  }

  const startDate = format(new Date(props.date), "yyyy-MM-dd", {
    locale: getDateFnsLocales(fabriqStore.locale),
  }) as string;

  const startTime = format(new Date(props.date), "HH:mm") as string;
  return { date: startDate, time: startTime };
});

const selectDate = async () => {
  if (props.readonly) return;
  let currentToast: any = null;
  const modal = await modalController.create({
    component: DateTimePickerModal,
    canDismiss: true,
    mode: "ios",
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    componentProps: {
      date: props.date,
      noFutureDate: true,
      canClearDate: false,
      onDone: async ({ date, time }: { date: string; time: string }) => {
        const now = new Date();
        const fullDate = new Date(inputDateToIsoDate(date, time));

        if (fullDate > now) {
          if (!currentToast) {
            const toast = await toastController.create({
              message: `🚨 ${t("eventContent.futureDateTime")}`,
              duration: 2000,
            });
            currentToast = toast;
            toast.present();
            toast.onDidDismiss().then(() => {
              currentToast = null;
            });
          }
          return;
        }
        modal.dismiss();

        emit("date", `${date}T${time}`);
      },
      onCancel: () => {
        modal.dismiss();
      },
    },
  });
  return modal.present();
};
</script>

<template>
  <ion-text class="due-date" @click.stop.prevent="selectDate">
    <span> {{ formated.date }} </span
    ><span>
      {{ formated.time }}
    </span>
  </ion-text>
</template>

<style scoped>
.due-date {
  padding-top: var(--ion-padding);
  font-size: var(--font-size-m);
  display: flex;
  align-items: center;
  gap: 0.5em;
}
</style>
