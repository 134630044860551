import { useClientStore } from "@/store/client";
import { Field, FieldType, Group } from "@/types";
import { computed, ref } from "vue";
import { useUsers } from "./useUsers";

export function getOrganizationFields(groups: Group[]) {
  const allFieldsWithDuplicates: Field[] = groups
    .flatMap((group: Group) => group.extra_fields)
    .filter((field: Field) => field.id);

  const uniqueFieldsMap = new Map<Field["id"], Field>();
  for (const field of allFieldsWithDuplicates) {
    uniqueFieldsMap.set(field.id, field);
  }

  return uniqueFieldsMap;
}

export function fieldIsInUserTeams(field: Field, userTeamIds: number[]) {
  return field.teams.some((teamId) => userTeamIds.includes(teamId));
}

export function getUserFieldsByType(
  organizationFields: Map<Field["id"], Field>,
  type: Field["type"],
  userTeamIds: number[]
) {
  return [...organizationFields.values()].filter((field) => {
    return field.type === type && fieldIsInUserTeams(field, userTeamIds);
  });
}

export function useFields() {
  const clientStore = useClientStore();
  const { userTeamIds } = useUsers();
  const organizationFields = ref(getOrganizationFields(clientStore.groups));

  const availableProductFields = computed(() => {
    return getUserFieldsByType(
      organizationFields.value,
      FieldType.Product,
      userTeamIds.value
    );
  });

  const availableEquipmentFields = computed(() => {
    return getUserFieldsByType(
      organizationFields.value,
      FieldType.Equipment,
      userTeamIds.value
    );
  });

  return {
    organizationFields,
    availableProductFields,
    availableEquipmentFields,
  };
}
