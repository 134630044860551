<script lang="ts" setup>
import Trend from "@ddgll/vue3-trend";
import { colorHex } from "@/utils/colors";
import { onMounted, computed, ref } from "vue";
import {
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { Indicator, Datapoint, IndicatorType } from "@/types";
import { useDatapointsStore } from "@/store/datapoints";
import {
  getComplementaryValue,
  formatValue,
  getDateFormat,
} from "@/utils/indicators";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { format, parse } from "date-fns";
import { useI18n } from "@/composables/useI18n";
import { getDateFnsLocales } from "@/i18n";
import { useFabriqStore } from "@/store/fabriq";

const props = defineProps<{
  indicator: Indicator;
}>();

const emit = defineEmits<{
  (event: "remove"): void;
}>();

const loading = ref(false);
const { t } = useI18n();
const fabriqStore = useFabriqStore();
const datapointsStore = useDatapointsStore();

const preview = computed(() => {
  if (props.indicator.indicator_type !== IndicatorType.TimeSeries) return null;
  if (props.indicator?.config?.preview) return props.indicator.config.preview;
  return "trend";
});

const color = computed(() => {
  if (!latestDataPoint.value) return colorHex("var(--ion-color-primary-shade)");
  if (latestDataPoint.value.target_met === true)
    return colorHex("var(--ion-color-success)");
  if (latestDataPoint.value.target_met === false)
    return colorHex("var(--ion-color-danger)");
  return colorHex("var(--ion-color-primary-shade)");
});

const latestDataPoint = computed(() => {
  if (!datapoints.value.length) return {};
  return datapoints.value[0];
});
const complementaryValue = computed(() =>
  getComplementaryValue(
    props.indicator,
    datapoints.value,
    latestDataPoint.value
  )
);
const latestDataPointValue = computed(() => {
  if (!latestDataPoint.value?.value || !props.indicator) return null;
  return formatValue(
    +String(latestDataPoint.value.value || "").replace(/\.?[0]*$/g, ""),
    props.indicator.unit_type,
    props.indicator.decimals || 0,
    props.indicator.power,
    props.indicator.config?.default_currency
  );
});

const latestDataPointDate = computed(() => {
  if (!latestDataPoint.value?.value || !props.indicator) return null;
  const dateFormat = getDateFormat(
    props.indicator?.frequency,
    t("formats.dayOfMonth")
  );
  return format(
    parse(latestDataPoint.value.end_date, "yyyy-MM-dd", new Date()),
    dateFormat,
    {
      locale: getDateFnsLocales(fabriqStore.locale),
    }
  );
});
const statusChart = computed(() => {
  if (!latestDataPoint.value?.value) return null;
  if (!props.indicator.choices.options) return null;
  for (const index in props.indicator.choices.options) {
    const option = props.indicator.choices.options[index];
    if (+option.value === +latestDataPoint.value.value) return option;
  }
  return null;
});
const indicatorValue = computed(() => {
  return props.indicator.stats_streak?.current?.streak || 0;
});
const reload = async (ev?: any) => {
  if (ev) {
    tracker.tunnel(
      "indicators",
      "indicators page",
      RecordCategory.Kpis,
      ActionType.Process,
      "reload"
    );
  }
  loading.value = true;
  await datapointsStore.all({ id: props.indicator.id });
  loading.value = false;
};
const datapoints = computed(() => {
  const datapoints = datapointsStore.collection.filter((d: Datapoint) => {
    return d.indicator === props.indicator.id;
  });
  datapoints.sort((a: Datapoint, b: Datapoint) =>
    b.end_date.localeCompare(a.end_date)
  );
  return datapoints;
});
const values = computed(() => {
  const points = datapoints.value
    .map((d: Datapoint) => parseFloat(d.value))
    .slice(0, 5);
  points.reverse();
  return points;
});
onMounted(() => reload());
</script>

<template>
  <ion-item-sliding class="indicator-preview">
    <ion-item-options side="start">
      <ion-item-option color="accent" @click="reload">
        <font-icon name="rotate" />
      </ion-item-option>
    </ion-item-options>
    <ion-item lines="none" class="indicator-preview-item">
      <ion-label class="indicator-name" slot="start">
        {{ indicator.name }}
      </ion-label>
      <ion-label
        class="indicator-value"
        :style="{ color }"
        v-if="
          indicator.indicator_type === 'time-series' &&
          latestDataPoint &&
          !loading
        "
      >
        {{ latestDataPointValue }}
        <div class="hint">{{ latestDataPointDate }}</div>
      </ion-label>
      <ion-label
        class="indicator-value"
        :style="{ color }"
        v-else-if="latestDataPoint && !loading"
      >
        <div class="hint">{{ latestDataPointDate }}</div>
      </ion-label>
      <ion-label v-if="loading" class="indicator-value indicator-spinner">
        <font-icon
          class="loading-color"
          spin
          name="spinner"
          color="var(--ion-color-primary-tint)"
          size="0.5"
        />
      </ion-label>
      <ion-label slot="end" class="indicator-details">
        <div v-if="statusChart">{{ statusChart.key }}</div>
        <div
          class="indicator-compl_value"
          v-if="indicator.indicator_type === 'counter'"
        >
          {{ indicatorValue }}
        </div>
        <div
          class="indicator-compl_value"
          v-if="
            preview === 'compl_value' &&
            indicator.indicator_type === 'time-series'
          "
        >
          {{ complementaryValue }}
        </div>
        <div v-if="preview === 'trend'" class="trend-preview">
          <trend
            v-if="values.length"
            :id="indicator.id"
            :data="values"
            :gradient="[color]"
            auto-draw
            smooth
          />
          <div v-else :style="{ color }">-</div>
        </div>
      </ion-label>
    </ion-item>
    <ion-item-options side="end">
      <ion-item-option @click="emit('remove')">
        <font-icon name="trash-can" color="var(--ion-color-danger)" regular />
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</template>

<style scoped>
.indicator-preview {
  padding: 0 var(--ion-padding);
  width: 100%;
  --ion-border: 0;
  --background: var(--ion-color-primary-contrast);
  background-color: var(--ion-color-primary-contrast);
  border-top: 1px solid var(--ion-border-color);
  height: 66px;
}
.indicator-preview-item {
  background-color: var(--ion-color-primary-contrast);
  --background: var(--ion-color-primary-contrast);
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --padding-start: calc(var(--ion-padding) / 2);
  --padding-end: 0;
}
.indicator-name {
  width: 30vw;
  max-width: 30vw;
  min-width: 30vw;
  font-size: var(--font-size-m);
}
.indicator-value {
  flex: 1;
  text-align: right;
  padding-right: 8px;
  font-size: calc(var(--font-size-l) * 1.25);
}

.indicator-spinner {
  width: 48px;
  height: 48px;
}
.loading-color {
  width: 48px;
  height: 48px;
}
.indicator-preview ion-item::part(native) {
  background-color: var(--ion-color-primary-contrast);
}
.indicator-preview:last-child {
  border-bottom: 1px solid var(--ion-border-color);
}
.indicator-details {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

.indicator-compl_value {
  font-size: var(--font-size-s);
  text-align: right;
  flex: 1 1 auto;
}
.trend-preview svg {
  stroke-width: 10px;
}

ion-item-options {
  border: 0;
}
ion-item-option {
  width: 4rem;
  background-color: var(--ion-background-color);
}
</style>
