<script lang="ts" setup>
import { ConnectionStatus, ConnectionType, Network } from "@capacitor/network";
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { nextTick, onMounted, watch } from "vue";
import { useFabriqStore } from "@/store/fabriq";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import storage from "@/utils/storage";
import { SplashScreen } from "@capacitor/splash-screen";
import { Keyboard } from "@capacitor/keyboard";
import tracker from "@/utils/tracker";
import { getCurrentBuild, performManualUpdate } from "./utils/live-updates";

const route = useRoute();
const router = useRouter();
const fabriqStore = useFabriqStore();
const { connected, initializing, token, platform } = storeToRefs(fabriqStore);

const connections: Array<ConnectionType> = ["wifi", "cellular"];
const onlineListener = (status: ConnectionStatus) => {
  tracker.setNetwork(status);
  fabriqStore.setOnline(
    status.connected && connections.includes(status.connectionType)
  );
};
Network.addListener("networkStatusChange", onlineListener);

async function getBuildVersion() {
  try {
    fabriqStore.setBuildVersionFromStorage();
    const buildVersion = await getCurrentBuild();
    fabriqStore.setBuildVersion(buildVersion);
    performManualUpdate();
  } catch (e) {
    console.error(e);
    fabriqStore.reportSentry(new Error("Failed to get build version"));
  }
}

onMounted(async () => {
  setTimeout(() => {
    SplashScreen.hide();
  }, 100);
  getBuildVersion();
  const orientation = localStorage.getItem("orientation");
  if (!orientation) {
    localStorage.setItem("orientation", "portrait");
    screen.orientation.lock("portrait");
  } else {
    screen.orientation.lock(
      orientation === "landscape" ? "landscape" : "portrait"
    );
  }
});

if (platform.value !== "web") {
  Keyboard.addListener("keyboardDidShow", () => {
    document.body.classList.add("keyboard");
  });
  Keyboard.addListener("keyboardDidHide", () => {
    document.body.classList.remove("keyboard");
  });
}

watch(
  () => connected.value && !initializing.value,
  (value: boolean) => {
    storage.set("connected", connected.value);
    nextTick(() => {
      if (document.body.classList.contains("loaded")) return;
      document.body.classList.add("loaded");
    });
    if (!connected.value && route.path !== "/login")
      return router.replace({ name: "Login" });
    if (value && route.path === "/login") {
      const pageToOpenAfterLogin = fabriqStore.pageToOpenAfterLogin;
      if (pageToOpenAfterLogin) {
        return router.replace(pageToOpenAfterLogin);
      }
      return router.replace({ name: "Tickets" });
    }
  }
);
watch(token, (value) => storage.set("token", value));
</script>
<template>
  <ion-app class="hide-scrollbar">
    <ion-router-outlet :swipe-gesture="false" class="main-outlet" />
  </ion-app>
</template>

<style>
* {
  overscroll-behavior-y: contain;
}
</style>
