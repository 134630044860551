import { useI18n } from "./useI18n";
import { Ref, ref } from "vue";
import { modalController, toastController } from "@ionic/vue";
import { useRouter } from "vue-router";
import { Execution, Indicator, Instance, Routine, TemplateStep } from "@/types";
import { useRoutinesStore } from "@/store/routines";
import { useInstancesStore } from "@/store/instances";
import { useExecutionsStore } from "@/store/executions";
import { useTemplatesStore } from "@/store/templates";
import { useCommentsStore } from "@/store/comments";
import { useIndicatorsStore } from "@/store/indicators";
import { useClientStore } from "@/store/client";
import { useRulesStore } from "@/store/rules";
import { useNoOffline } from "@/composables/useNoOffline";
import { useDatapointsStore } from "@/store/datapoints";

import {
  endOfDay,
  format,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  subBusinessDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { useFabriqStore } from "@/store/fabriq";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import RoutineInstanceSettingsModalVue from "@/components/modals/RoutineInstanceSettingsModal.vue";
import { formatDateForRoutine } from "@/utils/routines";

export const useStartInstance = () => {
  const tunnel = (action: string) => {
    tracker.tunnel(
      "routine",
      "routine page",
      RecordCategory.Routines,
      ActionType.Process,
      action
    );
  };

  const { t } = useI18n();
  const router = useRouter();
  const loading = ref(false);
  const loadingText: Ref<string | null> = ref(null);
  const { noOffline } = useNoOffline();
  const commentsStore = useCommentsStore();
  const routinesStore = useRoutinesStore();
  const instancesStore = useInstancesStore();
  const executionsStore = useExecutionsStore();
  const templatesStore = useTemplatesStore();
  const indicatorsStore = useIndicatorsStore();
  const rulesStore = useRulesStore();
  const fabriqStore = useFabriqStore();
  const clientStore = useClientStore();
  const datapointsStore = useDatapointsStore();

  interface RoutineInstanceSettings {
    teamId: number;
    auditee?: number | null;
    properties?: Record<string, number[]>;
  }

  const getIndicatorIds = (steps: Array<TemplateStep>) => {
    const ids: number[] = [];
    if (!steps?.length) return [];
    steps.forEach((el: TemplateStep) => {
      const { config } = el;
      const { indicatorId } = config;
      if (!indicatorId) return;
      ids.push(indicatorId);
    });
    return ids;
  };

  const loadIndicators = async (steps: Array<TemplateStep>) => {
    const indicatorsId = getIndicatorIds(steps);
    const promises: Promise<Indicator>[] = indicatorsId.map((id: number) =>
      indicatorsStore.load(id)
    );
    if (promises.length) {
      tracker.next("routine", ActionType.Request, "get indicators");
    }
    loadingText.value = t("routines.loader.indicators");
    return Promise.all(promises);
  };

  const loadDatapoints = async (indicators: Indicator[]) => {
    const promises = indicators.map((i) => {
      let fromDate = format(subBusinessDays(new Date(), 4), "yyyy-MM-dd");
      switch (i.frequency) {
        case "monthly":
          fromDate = format(
            startOfMonth(subMonths(new Date(), 1)),
            "yyyy-MM-dd"
          );
          break;

        case "weekly":
          fromDate = format(
            startOfISOWeek(subWeeks(new Date(), 1)),
            "yyyy-MM-dd"
          );
          break;

        default:
          break;
      }

      return datapointsStore.all({
        id: i.id,
        from_date: fromDate,
      });
    });
    if (promises.length) {
      tracker.next("routine", ActionType.Request, "get datapoints");
    }
    loadingText.value = t("routines.loader.datapoints");
    await Promise.all(promises);
  };

  const loadRules = async (steps: Array<TemplateStep>) => {
    if (clientStore.useKPIv3) {
      return;
    }
    const indicatorsId = getIndicatorIds(steps);
    const promises = indicatorsId.map((id: number) => rulesStore.load(id));
    if (promises.length) {
      tracker.next("routine", ActionType.Request, "get rules");
    }
    await Promise.all(promises);
  };

  const loadExecution = async (
    instance: Instance,
    steps: Array<TemplateStep>
  ) => {
    if (typeof instance.id === "number") {
      commentsStore.all({ instance: instance.id });
    }
    const id = instance.id || instance.uuid;
    const execution = executionsStore.collection.find(
      (t: Execution) => t.instance === id
    );
    if (!execution) {
      tracker.next("routine", ActionType.Request, "create execution");
      loadingText.value = t("routines.loader.steps");
      await executionsStore.add(
        {
          current_step_index: 1,
          instance: instance.id || instance.uuid,
          start_date: formatDateForRoutine(new Date()),
        },
        steps
      );
    }
    const indicators = await loadIndicators(steps);
    await loadRules(steps);
    await loadDatapoints(indicators);
    tracker.next("routine", ActionType.Ui, "process ui");
    loadingText.value = t("routines.loader.goto");
  };

  const getRoutine = (id: number) => {
    tracker.next("routine", ActionType.Request, "load routine");
    return routinesStore.load(id, false);
  };

  const getTemplate = (id: number) => {
    tracker.next("routine", ActionType.Request, "load template");
    return templatesStore.load(id, true);
  };

  const getTeamId = async (instance: Instance, routine: Routine) => {
    if (instance.scheduler?.team) return instance.scheduler?.team;
    const { teamId } = await getSettings(routine);
    return teamId;
  };

  const startScheduledInstance = async (instance: Instance) => {
    loading.value = true;
    tracker.begin(
      "routine",
      "Launch scheduled routine",
      RecordCategory.Routines,
      ActionType.Request
    );
    loadingText.value = t("routines.loader.version");
    const routine = await getRoutine(instance.routine_id);
    const template = await getTemplate(
      instance.template_id || routine.template_id
    );
    const { steps } = template;
    loadingText.value = t("routines.loader.instance");
    if (typeof instance.id === "string") {
      const teamId = await getTeamId(instance, routine);
      tracker.next("routine", ActionType.Request, "create instance");
      const today = new Date();
      const scheduler = routine.schedule.pop()?.scheduler;
      const newInstance = await instancesStore.create({
        ...instance,
        team_id: teamId,
        template_id: instance.template_id || routine.template_id,
        scheduler: {
          ...scheduler,
          has_started: true,
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezone_offset: (today.getTimezoneOffset() / 60) * -1,
      });
      await loadExecution(newInstance, steps);
      router.push(`/instances/${newInstance.uuid}`);
    } else if (instance.id) {
      await loadExecution(instance, steps);
      router.push(`/instances/${instance.uuid}`);
    } else {
      /**
       * 17/09/2024 | We are reportrting to Sentry momentarily to validate
       * a hypothesis.
       * To remove after 18/09/2024
       */
      const fabriqStore = useFabriqStore();
      fabriqStore.reportSentry(
        new Error("startScheduledInstance: No instance id")
      );
      resetLoading();
      const toast = await toastController.create({
        message: t("common.unknownError"),
        duration: 2000,
      });
      toast.present();
      return;
    }
    tracker.next("routine", ActionType.Process, "go to instance");
    tunnel("start scheduled");
    resetLoading();
  };

  const shouldOpenSettingsModal = (routine: Routine) => {
    if (routine.teams.length > 1) return true;
    return routine.config?.promptScopeSelectionModal;
  };

  const getSettings = (routine: Routine): Promise<RoutineInstanceSettings> => {
    return new Promise(async (resolve, reject) => {
      if (!routine.teams.length) reject("No team in routine");
      if (!shouldOpenSettingsModal(routine)) {
        return resolve({ teamId: routine.teams[0] });
      }
      const modal = await modalController.create({
        component: RoutineInstanceSettingsModalVue,
        canDismiss: true,
        mode: "ios",
        componentProps: {
          routine,
          onDone: (settings: RoutineInstanceSettings) => {
            resolve(settings);
            modal.dismiss();
          },
          onCancel: () => {
            reject();
            modal.dismiss();
          },
        },
      });
      modal.present();
    });
  };

  const startRoutine = async (routineInStore: Routine) => {
    if (!fabriqStore.online) return noOffline();
    try {
      const routineId =
        typeof routineInStore.id === "string"
          ? parseInt(routineInStore.id)
          : routineInStore.id;
      if (!routineId) {
        const routineIdError = new Error("Routine id should be defined");
        fabriqStore.reportSentry(routineIdError, {
          routineId,
        });
        throw routineIdError;
      }
      const routine = await getRoutine(routineId);

      if (!routine) {
        const routineNotFoundError = new Error(
          `Routine with id ${routineId} not found`
        );
        fabriqStore.reportSentry(routineNotFoundError, {
          routineId,
        });
        throw routineNotFoundError;
      }
      const settings = await getSettings(routine);
      if (!settings) {
        const routineSettingsNotFoundError = new Error(
          `Routine settings not found for routine with id ${routineId}`
        );
        fabriqStore.reportSentry(routineSettingsNotFoundError, {
          routine,
        });
        throw routineSettingsNotFoundError;
      }
      tracker.begin(
        "routine",
        "Launch unscheduled routine",
        RecordCategory.Routines,
        ActionType.Request
      );
      loadingText.value = t("routines.loader.version");
      const template = await getTemplate(routine.template_id);
      if (!template) {
        const routineTemplateNotFoundError = new Error(
          `Routine template with id ${routine.template_id} not found`
        );
        fabriqStore.reportSentry(routineTemplateNotFoundError, {
          routine,
        });
        throw routineTemplateNotFoundError;
      }
      const { steps } = template;
      loadingText.value = t("routines.loader.instance");
      loading.value = true;
      const today = new Date();
      const addedInstance = await instancesStore.add({
        all_day: true,
        start_date: startOfDay(today),
        end_date: endOfDay(today),
        has_started: true,
        instructions: "",
        is_done: false,
        is_dst: true,
        users: [fabriqStore.user?.id],
        routine_id: routine.id,
        template_id: routine.template_id,
        team_id: settings.teamId
          ? JSON.parse(JSON.stringify(settings.teamId))
          : undefined,
        auditee: settings.auditee
          ? JSON.parse(JSON.stringify(settings.auditee))
          : undefined,
        properties: settings.properties
          ? JSON.parse(JSON.stringify(settings.properties))
          : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezone_offset: (today.getTimezoneOffset() / 60) * -1,
      });
      await instancesStore.save();
      const instance = instancesStore.collection.find(
        (i: Instance) => i.uuid === addedInstance.uuid
      );
      tracker.next("routine", ActionType.Request, "create execution");
      loadingText.value = t("routines.loader.steps");
      await executionsStore.add({
        current_step_index: 1,
        instance: instance.id,
        start_date: formatDateForRoutine(today),
      });
      await executionsStore.save();

      const indicators = await loadIndicators(steps);
      if (!indicators) {
        const indicatorsNotFoundError = new Error(
          `Indicators not found for routine with id ${routineId}`
        );
        fabriqStore.reportSentry(indicatorsNotFoundError, {
          routine,
        });
        throw indicatorsNotFoundError;
      }
      await loadRules(steps);
      await loadDatapoints(indicators);

      fabriqStore.setLoading(false);
      tracker.next("routine", ActionType.Process, "go to instance");
      router.push(`/instances/${instance.uuid}`);
      tunnel("start unscheduled");
      resetLoading();
    } catch (e) {
      console.error(e);
      const toast = await toastController.create({
        message: t("routines.startInstanceError"),
        duration: 2000,
        color: "danger",
      });
      loading.value = false;
      fabriqStore.setLoading(false);
      toast.present();
    }
  };

  let timer: NodeJS.Timeout;
  const resetLoading = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      loading.value = false;
      loadingText.value = "";
    }, 1000);
  };

  return {
    loading,
    loadingText,
    startScheduledInstance,
    startRoutine,
  };
};
