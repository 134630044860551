<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import {
  IonToolbar,
  modalController,
  onIonViewDidEnter,
  useIonRouter,
  IonModal,
} from "@ionic/vue";
import FieldPickerModalWithFieldsInProps from "@/components/modals/FieldPickerModalWithFieldsInProps.vue";
import FButton from "@/components/ui/FButton.vue";
import { useI18n } from "@/composables/useI18n";
import { useFabriqStore } from "@/store/fabriq";
import { storeToRefs } from "pinia";
import RoutePage from "@/components/ui/RoutePage.vue";
import LoadingBlock from "@/components/ui/LoadingBlock.vue";
import EventCreateModal from "@/components/modals/EventCreateModal.vue";
import { ticketPageAnimation } from "@/utils/animations";
import AccordionItem from "@/components/ui/AccordionItem.vue";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { calcModalPercents } from "@/utils/modals";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { useEventsStore } from "@/store/events";
import { useEventTypesStore } from "@/store/eventTypes";
import { useClientStore } from "@/store/client";
import { useEventFiltersStore } from "@/store/eventFilters";
import { EventType, FabriqEventWithReadableProperties } from "@/types";
import { localize } from "@/utils/localize";

import EventList from "@/components/business/events/EventList.vue";
import { useEventsWithPropertiesStore } from "@/store/eventsWithProperties";
import { format } from "date-fns";
import { getDateFnsLocales } from "@/i18n";

const fabriqStore = useFabriqStore();
const eventsStore = useEventsStore();
const eventTypesStore = useEventTypesStore();
const eventFiltersStore = useEventFiltersStore();
const eventsWithPropertiesStore = useEventsWithPropertiesStore();
const clientStore = useClientStore();
const router = useIonRouter();
const { t } = useI18n();

const { locale } = fabriqStore;

const { loading } = storeToRefs(eventsStore);
const { eventTypes, allEventTypesDateTimeProperties } = storeToRefs(
  eventsWithPropertiesStore
);
const { eventList, sortOrderAscending, availableZones } =
  storeToRefs(eventFiltersStore);

const daysList = computed(() => {
  const days: Record<string, FabriqEventWithReadableProperties[]> = {
    nodate: [],
  };

  const today = new Date().toISOString().split("T")[0];

  eventList.value.forEach((event) => {
    const datetimeProperty =
      allEventTypesDateTimeProperties.value[event.eventTypeId];
    if (!datetimeProperty) {
      return;
    }
    const datetime = event.properties.find(
      (property) => property.eventPropertyTypeId === datetimeProperty.id
    )?.value;
    const date = datetime
      ? new Date(datetime).toISOString().split("T")[0]
      : "nodate";

    const dayEvents = days[date === today ? "today" : date] || [];
    dayEvents.push(event);
    days[date === today ? "today" : date] = dayEvents;
  });
  if (days.nodate.length === 0) {
    delete days.nodate;
  }

  const sortedKeys = sortOrderAscending.value
    ? Object.keys(days).sort()
    : Object.keys(days).sort((a, b) => b.localeCompare(a));

  return sortedKeys.reduce((obj, key) => {
    obj[key] = days[key];
    return obj;
  }, {} as Record<string, FabriqEventWithReadableProperties[]>);
});

const dateFormat = t("formats.dayOfMonth");
function dayOfMonth(date: string) {
  return format(new Date(date), dateFormat, {
    locale: getDateFnsLocales(fabriqStore.locale),
  });
}

const filteredZones = computed(() => {
  if (!selectedEventTypeId.value) {
    return availableZones.value;
  }
  const eventType = eventTypes.value.find(
    (et: EventType) => et.id === selectedEventTypeId.value
  );
  if (!eventType) {
    return availableZones.value;
  }
  return eventFiltersStore.filteredZoneFromEventType(eventType);
});

const observer: IntersectionObserver = new IntersectionObserver(
  (entries) => {
    if (entries.some((entry) => entry.isIntersecting)) {
      handleIntersect();
    }
  },
  { threshold: 0.8 }
);

const tunnel = (action: string) => {
  tracker.tunnel(
    "events",
    "events page",
    RecordCategory.Events,
    ActionType.Process,
    action
  );
};

tracker.end("init");
tracker.end("connexion");

const hasEvents = computed(() => eventList.value.length > 0);
const intersectSpace = ref();
const showIntersectSpace = ref(false);
const eventsList = ref();
const scrollPosition = ref(0);

const loadAllData = async (ev?: any) => {
  const load = fabriqStore.page !== "events";
  fabriqStore.setPage("events");
  if (!ev) {
    if (!load) return;
    loading.value = true;
    eventFiltersStore.loadEventListWithFilters({ flush: true });
  } else if (ev) {
    await Promise.all([
      eventTypesStore.all({ flush: true }),
      eventFiltersStore.loadEventListWithFilters({ flush: true }),
    ]);
    loading.value = false;
  }
  ev?.target?.complete();
};

onMounted(() => {
  fabriqStore.setInitializing(false);
});

const useEvents = computed(() => {
  return eventTypesStore.collection.length > 0 && clientStore.config.useEvents;
});

const eventTypeSelectorText = computed(() => {
  if (selectedEventTypeId.value) {
    const eventType = eventTypes.value.find(
      (z: any) => z.id === selectedEventTypeId.value
    );
    return localize(eventType?.label, locale, 0);
  }
  return t("events.eventType", 2);
});

const zoneSelectorText = computed(() => {
  if (selectedZoneId.value) {
    const zone = availableZones.value.find(
      (z: any) => z.id === selectedZoneId.value
    );
    return zone?.name;
  }
  return t("zone", 2);
});

const openAddEvent = async () => {
  if (!useEvents.value) return;
  tunnel("create event");
  tracker.begin(
    "createEvent",
    "Event creation",
    RecordCategory.Events,
    ActionType.Ui,
    "click"
  );
  const breakpoints = calcModalPercents(
    44,
    eventTypesStore.collection.length,
    210
  );
  const modal = await modalController.create({
    component: EventCreateModal,
    canDismiss: true,
    mode: "ios",
    breakpoints,
    initialBreakpoint: breakpoints[1],
    componentProps: {
      onDone: async (eventTypeId: string) => {
        gotoEventCreation(eventTypeId);
        await modal.dismiss();
      },
      onCancel: () => {
        modal.dismiss();
      },
    },
  });
  return modal.present();
};

const gotoEvent = (uuid: string) => {
  tracker.begin(
    "event",
    "Open event from events",
    RecordCategory.Events,
    ActionType.Process,
    "click"
  );
  mixpanelTracker.track("open | event | events screen");
  router.navigate(`/events/${uuid}`, "forward", "push", ticketPageAnimation);
};

const gotoEventCreation = (eventTypeId: string) => {
  tracker.begin(
    "event",
    "create event from events",
    RecordCategory.Events,
    ActionType.Process,
    "click"
  );
  mixpanelTracker.track("create | event | events screen");
  router.navigate(
    `/events/create/${eventTypeId}`,
    "forward",
    "push",
    ticketPageAnimation
  );
};

onIonViewDidEnter(() => {
  loadAllData();
});

watch(
  intersectSpace,
  (el, old) => {
    if (el && !old) {
      observer.observe(el);
    }
  },
  { immediate: true }
);

let loaded = false;
watch(
  eventTypes,
  (n) => {
    if (n?.length > 0 && !loaded) {
      loaded = true;
      eventFiltersStore.setEventTypeId(null);
    }
  },
  { immediate: true }
);

watch(daysList, (n) => {
  if (Object.keys(n).length > 0) {
    showIntersectSpace.value = true;
  }
});

onBeforeUnmount(() => {
  observer?.disconnect();
});

function handleIntersect() {
  if (loading.value) return;
  if (!showIntersectSpace.value) return;
  scrollPosition.value = eventsList.value.$el.parentElement.scrollTop;
  eventFiltersStore.loadMore();
}

const eventTypeSelectorModal = ref(false);
const selectedEventTypeId = ref(null);
const selectedEventTypeValues = computed(() => {
  return selectedEventTypeId.value ? [selectedEventTypeId.value] : [];
});

const availableEventTypes = computed(() => {
  return eventTypes.value.map((et: any) => ({
    ...et,
    name: localize(et.label, locale, 0),
  }));
});
function openEventTypeSelector() {
  eventTypeSelectorModal.value = true;
}
function selectEventType(eventTypes_: any) {
  const newEventTypeId = eventTypes_?.length ? eventTypes_[0] : null;
  eventTypeSelectorModal.value = false;
  if (newEventTypeId === selectedEventTypeId.value) {
    return;
  }
  mixpanelTracker.track("filter | events type | events mobile tab");
  selectedEventTypeId.value = newEventTypeId;
  selectedZoneId.value = null;
  eventFiltersStore.setEventTypeId(selectedEventTypeId.value);
}

const selectedZoneId = ref(null);
const selectedZoneValues = computed(() => {
  return selectedZoneId.value ? [selectedZoneId.value] : [];
});

const zoneSelectorModal = ref(false);
function openZoneSelector() {
  zoneSelectorModal.value = true;
}

function selectZone(zones: any) {
  mixpanelTracker.track("filter | zone | events mobile tab");
  selectedZoneId.value = zones?.length
    ? zones.filter((z) => z !== selectedZoneId.value)[0] ?? null
    : null;
  eventFiltersStore.setZoneId(selectedZoneId.value);
  zoneSelectorModal.value = false;
}
</script>

<template>
  <route-page
    :title="t('event', 2)"
    fabIcon="add"
    class="events-page"
    :scrollY="70"
    :transparent="true"
    :beta="true"
    data-qa="add-event-btn"
    @refresh="loadAllData"
    @fab="openAddEvent"
  >
    <template v-slot:toolbar>
      <ion-toolbar>
        <div class="filter-btns">
          <f-button
            selected
            class="event-type-filter-btn"
            data-qa="event-type-filter-btn"
            @click="openEventTypeSelector"
          >
            <div class="event-type-filter-btn-content ion-margin-horizontal">
              <div class="filter-btn-label">{{ eventTypeSelectorText }}</div>
              <font-icon
                name="chevron-down"
                color="var(--f-button-color)"
                size="0.55"
              />
            </div>
          </f-button>
          <f-button
            selected
            class="zone-filter-btn"
            data-qa="zone-filter-btn"
            :disabled="!selectedEventTypeId"
            @click="openZoneSelector"
          >
            <div class="zone-filter-btn-content ion-margin-horizontal">
              <div class="filter-btn-label">{{ zoneSelectorText }}</div>
              <font-icon
                name="chevron-down"
                color="var(--f-button-color)"
                size="0.55"
              />
            </div>
          </f-button>
        </div>
      </ion-toolbar>
    </template>
    <div id="events-accordions">
      <loading-block
        v-if="loading && !hasEvents"
        class="events-loading-spinner"
      />
      <template v-else>
        <accordion-item
          v-for="(eventList, index) in daysList"
          :label="index === 'today' ? t('datetime.today') : dayOfMonth(index)"
          :key="`events-${index}`"
          data-qa="events-list-day"
          :data-qa-value="index"
        >
          <EventList :events="eventList" @select="gotoEvent($event)" />
        </accordion-item>
      </template>
      <div
        v-if="!loading && !hasEvents"
        class="routines-empty ion-margin-horizontal"
      >
        <div class="icon">😌</div>
        <div>{{ t("events.noEvent") }}</div>
        <div class="hint">{{ t("events.placeholders.noEvent") }}</div>
      </div>
      <br />
    </div>
    <ion-modal
      :is-open="zoneSelectorModal"
      mode="ios"
      :breakpoints="[0, 1]"
      :initialBreakpoint="1"
      @ionModalDidDismiss="zoneSelectorModal = false"
    >
      <FieldPickerModalWithFieldsInProps
        :allowedValues="filteredZones"
        :selectedValues="selectedZoneValues"
        :label="$t('zone', 2)"
        :placeholder="$t(`tickets.placeholders.zone`)"
        :chooseAFieldLabel="$t(`tickets.choices.zone`)"
        @done="selectZone(null)"
        @update="selectZone"
      />
    </ion-modal>
    <ion-modal
      :is-open="eventTypeSelectorModal"
      mode="ios"
      :breakpoints="[0, 1]"
      :initialBreakpoint="1"
      @ionModalDidDismiss="eventTypeSelectorModal = false"
    >
      <FieldPickerModalWithFieldsInProps
        :allowedValues="availableEventTypes"
        :selectedValues="selectedEventTypeValues"
        :label="$t('events.eventType', 1)"
        :placeholder="$t('events.eventType', 1)"
        :chooseAFieldLabel="$t(`events.choices.eventType`)"
        @done="selectEventType(null)"
        @update="selectEventType"
      />
    </ion-modal>
  </route-page>
</template>

<style scoped>
.search-input {
  margin: var(--f-padding-m) var(--ion-padding);
}

.filter-btns {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.events-loading-spinner {
  margin-top: calc(var(--ion-padding) * 2 + 70px);
}

.event-type-filter-btn,
.zone-filter-btn {
  width: fit-content;
  max-width: calc(50% - 0.5rem);
}

.filter-btn-label {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.event-type-filter-btn-content,
.zone-filter-btn-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}
</style>
