<script lang="ts" setup>
import { ComputedRef, computed, onBeforeUnmount, onMounted, ref } from "vue";
import { IonItemGroup, IonItem } from "@ionic/vue";
import {
  EventPropertyWithReadableValue,
  FabriqEventWithReadableProperties,
} from "@/types";
import { useGetEventPropertiesByType } from "@/composables/useGetEventPropertiesByType";
import FontIcon from "@/components/ui/FontIcon.vue";
import { format } from "date-fns";
import { useFabriqStore } from "@/store/fabriq";
import { useClientStore } from "@/store/client";
import { storeToRefs } from "pinia";
import { useEventFiltersStore } from "@/store/eventFilters";
import { localize } from "@/utils/localize";
import { useDiamondDependenciesStore } from "@/store/diamondDependencies";
import { useAddTicketFromEvent } from "@/composables/useAddTicketFromEvent";
import { getShortDescriptionFromEventProperties } from "@/utils/events";
import CachedAvatar from "@/components/ui/CachedAvatar.vue";
import { transformUrl } from "@/utils/files";
import { getUserAvatarUrl } from "@/utils/getUserAvatar";

type EventsListItemProps = {
  fEvent: FabriqEventWithReadableProperties;
};

const props = defineProps<EventsListItemProps>();

const {
  getEventPropertiesByType,
  getPropertyByEventPropertyTypeId,
  eventType,
} = useGetEventPropertiesByType(computed(() => props.fEvent));

const fabriqStore = useFabriqStore();
const clientStore = useClientStore();
const eventFilterStore = useEventFiltersStore();
const dependenciesStore = useDiamondDependenciesStore();

const { eventTypeId } = storeToRefs(eventFilterStore);

const { usersWithFutureUserId: users } = storeToRefs(clientStore);

const { online } = storeToRefs(fabriqStore);

const eventTypeLabel = computed(() => {
  return localize(eventType.value?.label, fabriqStore.locale, 0);
});

const emoji = computed(() => {
  return eventType.value?.config?.emoji;
});

const eventZoneValue = computed(() => {
  const zone = getEventPropertiesByType(props.fEvent, "zone");
  if (!zone) {
    return "no zone";
  }
  return zone.readableValue ? zone.readableValue : `[${zone.value}]`;
});

const eventTimeValue: ComputedRef<string> = computed(() => {
  const dateValue = (
    (getEventPropertiesByType(props.fEvent, "datetime")?.value as string) ?? ""
  ).replace("Z", "");
  if (!dateValue) return "";
  return format(new Date(dateValue), "HH:mm");
});
const mainPropertiesReadableValues = computed(() => {
  const propertyNames = props.fEvent.properties.reduce<string[]>(
    (acc: string[], property: EventPropertyWithReadableValue) => {
      const propertyType = getPropertyByEventPropertyTypeId(
        property.eventPropertyTypeId
      );

      if (!propertyType) return acc;

      if (!property?.readableValue) return acc;

      // FIXME for now, we use ordinal/cardinal values as main properties
      // eventually, we need to taint a property as main property
      const mainProperties = ["ordinal", "cardinal", "equipment", "product"];
      if (mainProperties.includes(propertyType.type)) {
        return [...acc, property.readableValue];
      }

      return acc;
    },
    []
  );

  return propertyNames;
});

const eventElement = ref(null);

const { openAddMenu, eventDependencies, dependenciesLoading, canEdit } =
  useAddTicketFromEvent(props.fEvent, true);

const observer: IntersectionObserver = new IntersectionObserver(
  (entries) => {
    if (entries.some((entry) => entry.isIntersecting)) {
      loadDependencies();
    }
  },
  { threshold: 0.8 }
);

onMounted(() => {
  if (eventElement.value) observer.observe(eventElement.value);
});

onBeforeUnmount(() => {
  observer?.disconnect();
});

async function loadDependencies() {
  dependenciesLoading.value = true;

  if (eventDependencies.value.length) {
    dependenciesLoading.value = false;
    return;
  }
  await dependenciesStore.all({
    entityType: "event",
    entityId: props.fEvent.id,
  });

  observer?.disconnect();
  dependenciesLoading.value = false;
}

const eventShortDescriptionValue = computed(() => {
  const { properties } = props.fEvent;
  const shortDescriptionProperty =
    getShortDescriptionFromEventProperties(properties);
  if (!shortDescriptionProperty) return;
  return shortDescriptionProperty?.readableValue;
});

const user = computed(() => {
  return users.value?.find((u) => u.future_user_id === props.fEvent.createdBy);
});

const avatar = computed(() => {
  if (!user.value) return;
  const url = getUserAvatarUrl(user.value);
  if (!url) return;
  if (url.match(/\.webp/)) return url;

  const subdomainThumbnailUrl = transformUrl(
    url,
    fabriqStore.token,
    fabriqStore.subdomain
  );

  return subdomainThumbnailUrl;
});

const initials = computed(() => {
  const fUser = users.value?.find(
    (u) => u.future_user_id === props.fEvent.createdBy
  );
  if (!fUser) return "";
  return (
    (fUser.first_name ?? "").substring(0, 1) +
    (fUser.last_name ?? "").substring(0, 1)
  );
});

const showIncompleteTooltip = computed(() => {
  return !props.fEvent.isComplete;
});
</script>

<template>
  <ion-item-group class="f-list-item f-list-item-ticket clickable">
    <ion-item
      lines="none"
      class="event-divider"
      data-qa="event-list-item"
      :data-qa-value="fEvent.id"
      @click="$emit('select', fEvent.uuid)"
    >
      <div class="event-container" ref="eventElement">
        <div class="event-body">
          <div class="event-row" v-if="eventTypeId == null">
            <div class="event-type" v-if="eventTypeLabel">
              <div v-if="emoji" class="emoji-item">
                {{ emoji }}
              </div>
              <div class="event-type-label">
                {{ eventTypeLabel }}
              </div>
            </div>

            <div class="event-type-right-side">
              <font-icon
                v-if="showIncompleteTooltip"
                name="warning"
                material
                size="1.3"
                color="var(--ion-color-warning)"
              />

              <div class="add-ticket-button">
                <font-icon
                  v-if="dependenciesLoading"
                  name="spinner"
                  spin
                  size="0.3"
                  class="dependencies-loading"
                />
                <font-icon
                  v-else
                  size="1.5"
                  name="post_add"
                  material
                  :color="
                    !online || !canEdit
                      ? 'var(--f-color-disabled)'
                      : 'var(--f-color-primary)'
                  "
                  data-qa="create-or-open-ticket"
                  :data-qa-value="fEvent.id"
                  @click.stop.prevent="openAddMenu"
                />
                <div v-if="eventDependencies.length" class="badge" />
              </div>
            </div>
          </div>

          <div
            class="event-short-description"
            v-if="eventShortDescriptionValue"
          >
            {{ eventShortDescriptionValue }}
          </div>

          <div class="event-footer">
            <div class="event-properties">
              <div class="event-property">
                <font-icon
                  size="0.7"
                  color="var(--f-color-discrete)"
                  name="location-dot"
                />
                <p class="event-property-text">{{ eventZoneValue }}</p>
              </div>
              <div
                v-for="mainPropertiesReadableValue of mainPropertiesReadableValues"
                class="event-property"
                :key="mainPropertiesReadableValue"
              >
                <p class="event-property-text">
                  {{ mainPropertiesReadableValue }}
                </p>
              </div>
            </div>
            <div class="spacer" />

            <div class="event-author-time">
              <div class="event-time">
                {{ eventTimeValue }}
              </div>

              <CachedAvatar :user="avatar" :fallback="initials" />
            </div>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-item-group>
</template>

<style scoped>
.event-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 6px 0px;
}

.event-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.event-type-right-side {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.event-author-time {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.event-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.event-properties {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.event-property {
  font-size: var(--font-size-s);
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  border: 1px solid var(--ion-border-color);
  border-radius: 12px;
  padding: 4px 8px;
}

.event-property-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.event-type {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: var(--font-size-m);
  overflow: hidden;

  .event-type-label {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
}

.event-time {
  display: flex;
  align-items: center;
  font-size: var(--font-size-s);
  color: var(--f-color-discrete);
}

.event-short-description {
  font-size: var(--font-size-s);
  color: var(--f-color-discrete);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-divider::part(native) {
  --background: transparent;
  padding: calc(var(--ion-padding) / 2) 0 calc(var(--ion-padding) / 2)
    var(--ion-padding);
  display: flex;
  align-items: center;
  border: 0;
}

.add-ticket-button {
  position: relative;
}

.badge {
  --badge-size: 0.6rem;
  width: var(--badge-size);
  height: var(--badge-size);
  background-color: var(--ion-color-secondary);
  border-radius: calc(var(--badge-size));
  position: absolute;
  top: 1px;
  right: 1px;
}

.dependencies-loading {
  margin-block: 4.8px;
  margin-inline: 6px;
}

.event-footer {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-end;
}
</style>
