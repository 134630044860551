import { computed } from "vue";
import { useClientStore } from "@/store/client";
import { Team } from "@/types";
import { useTeamsStore } from "@/store/teams";
import { useFabriqStore } from "@/store/fabriq";

export const useUsers = () => {
  const clientStore = useClientStore();
  const teamsStore = useTeamsStore();
  const fabriqStore = useFabriqStore();

  const users = computed(() => {
    if (!clientStore.users || !clientStore.users.length) return [];
    return clientStore.users;
  });

  const userTeamIds = computed(() => {
    return teamsStore.collection
      .filter((team: Team) => {
        const currentUserId = fabriqStore.user?.id;
        const userIsInTeam = team.users.some(
          (userId) => userId === currentUserId
        );

        return userIsInTeam;
      })
      .map((team: Team) => team.id);
  });

  return {
    users,
    userTeamIds,
  };
};
