import { createListStore } from "./list";
import { RoutineService } from "@/services/routines";

export const useRoutinesStore = createListStore(
  "routines",
  RoutineService,
  null,
  null,
  {
    async loadAll() {
      if (!RoutineService.all) return;
      this.loading = true;
      const routines = await RoutineService.all();
      this.merge(routines, true);
      this.loading = false;
    },
  },
  true
);
