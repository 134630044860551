<template>
  <ion-text class="due-date" @click.stop.prevent="selectDate">
    <font-icon
      v-if="icon"
      :name="'today'"
      :size="iconSize"
      :color="color"
      material
    />
    <span :style="{ color: endFormated.length ? 'inherit' : color }">
      {{ formated }}
    </span>
    <span v-if="endFormated?.length">→</span>
    <span v-if="endFormated?.length" :style="{ color }">{{ endFormated }}</span>
  </ion-text>
</template>

<script lang="ts">
import { IonText, modalController } from "@ionic/vue";
import { defineComponent, computed } from "vue";
import {
  parseISO,
  parse,
  isBefore,
  isEqual,
  format,
  subDays,
  addDays,
} from "date-fns";
import { useI18n } from "@/composables/useI18n";
import DatePickerModal from "@/components/modals/DatePickerModal.vue";
import { getDateFnsLocales } from "@/i18n";
import { useFabriqStore } from "@/store/fabriq";

export default defineComponent({
  name: "DueDate",
  components: {
    IonText,
  },
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: "0.875",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "",
      require: true,
    },
    end: {
      type: String,
      default: "",
      require: true,
    },
  },
  emits: ["modal", "date"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const fabriqStore = useFabriqStore();

    const df = "yyyy-MM-dd";
    const now = new Date();
    const yesterday = parse(format(subDays(now, 1), df), df, now);
    const compare = parse(format(now, df), df, now);
    const tomorrow = parse(format(addDays(now, 1), df), df, now);
    const color = computed(() => {
      const date = props.end?.length ? props.end : props.date;
      if (!date || !date.length) return "var(--ion-color-primary-shade)";
      const parsed = parse(format(parseISO(date), df), df, now);
      if (isEqual(parsed, compare)) return "var(--ion-color-success)";
      if (isEqual(parsed, tomorrow)) return "var(--ion-color-success)";
      if (isBefore(parsed, compare)) return "var(--ion-color-danger)";
      return "var(--ion-color-primary)";
    });
    const formated = computed(() => {
      if (!props.date || !props.date.length) {
        return props.iconOnly ? "" : t("datetime.emptyDueDate");
      }
      const parsed = parseISO(props.date);
      if (isEqual(parsed, yesterday)) return t("dates.yesterday");
      if (isEqual(parsed, compare)) return t("dates.today");
      if (isEqual(parsed, tomorrow)) return t("dates.tomorrow");
      return format(parsed, t("formats.dayOfMonth"), {
        locale: getDateFnsLocales(fabriqStore.locale),
      });
    });
    const endFormated = computed(() => {
      if (!props.end || !props.end.length) {
        return "";
      }
      const parsed = parseISO(props.end);
      if (isEqual(parsed, yesterday)) return t("dates.yesterday");
      if (isEqual(parsed, compare)) return t("dates.today");
      if (isEqual(parsed, tomorrow)) return t("dates.tomorrow");
      return format(parsed, t("formats.dayOfMonth"), {
        locale: getDateFnsLocales(fabriqStore.locale),
      });
    });

    const selectDate = async () => {
      if (props.readonly) return;
      const modal = await modalController.create({
        component: DatePickerModal,
        canDismiss: true,
        mode: "ios",
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        componentProps: {
          date: props.date,
          end: props.end,
          onDone: (dates: { start: string | null; end: string | null }) => {
            modal.dismiss();
            emit("date", dates);
          },
          onCancel: () => {
            modal.dismiss();
          },
        },
      });
      return modal.present();
    };

    return {
      selectDate,
      formated,
      endFormated,
      color,
    };
  },
});
</script>

<style scoped>
.due-date {
  padding-top: 2px;
  font-size: var(--font-size-s);
  display: flex;
  align-items: center;
  gap: 0.5em;
}
</style>
