<template>
  <ion-header mode="ios">
    <ion-toolbar
      class="transparent-toolbar edit-modal-toolbar"
      style="margin-bottom: 0"
    >
      <ion-title>{{ t("common.assignee") }}</ion-title>
      <ion-buttons slot="end">
        <ion-button fill="clear" class="cancel-btn" @click="$emit('cancel')">
          {{ t("common.done") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <div
      class="modal-header"
      style="background-color: var(--ion-color-primary-contrast)"
    >
      <div
        class="ion-padding-start ion-padding-end ion-padding-bottom ion-margin-horizontal"
        style="background-color: var(--ion-color-primary-contrast)"
      >
        <ion-input
          class="filter-input"
          :placeholder="t('common.placeholders.assignee')"
          v-model="userFilter"
        />
        <!-- <ion-input
          class="filter-input"
          v-else
          :placeholder="t('common.placeholders.assignee')"
        /> -->
        <colored-chip
          color="transparent"
          class="ion-margin-top"
          clearable
          v-if="user"
          :key="user.id"
          :avatar="avatar"
          :fallback="fallback"
          :label="fullName"
          @remove="$emit('done', null)"
        />
      </div>
      <div class="list-title">{{ t("common.choices.assignee") }}</div>
    </div>
  </ion-header>
  <ion-content :scroll-y="false">
    <ion-list lines="none" ref="list" class="scroller ion-padding-horizontal">
      <recycle-scroller
        ref="scroller"
        class="ion-content-scroll-host scroller"
        :items="filteredUsers"
        :item-size="44"
      >
        <template #default="{ item }">
          <ion-item class="user-item" @click="$emit('done', item)">
            <user-avatar slot="start" :user="item" :key="item.id" />
            <div class="user-content ion-margin-start">
              <div class="user-name">{{ item.fullName }}</div>
              <div class="user-email">{{ item.email }}</div>
            </div>
          </ion-item>
        </template>
      </recycle-scroller>
    </ion-list>
  </ion-content>
</template>
<script lang="ts">
// class="ion-content-scroll-host"
import { computed, defineComponent, onMounted, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useAutofocus } from "@/composables/useAutofocus";
import { useFabriqStore } from "@/store/fabriq";
import UserAvatar from "@/components/business/UserAvatar.vue";
import { User } from "@/types";

import { useModalScroller } from "@/composables/useModalScroller";
import { useClientStore } from "@/store/client";
import { getUserAvatarUrl } from "@/utils/getUserAvatar";

export default defineComponent({
  name: "UserPickerModalWithUsersInProps",
  props: {
    selectedUserId: {
      type: Number,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
    onlyMe: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonContent,
    IonInput,
    IonTitle,
    IonButtons,
    IonButton,
    IonHeader,
    IonToolbar,
    IonList,
    IonItem,
    UserAvatar,
  },
  emits: ["done", "cancel"],
  setup(props) {
    const clientStore = useClientStore();
    const { locale } = useFabriqStore();
    const userFilter = ref("");

    onMounted(() => useAutofocus(".filter-input input"));

    const user = computed(() => {
      if (!clientStore.users || !clientStore.users.length) return null;
      return clientStore.users.find((u: User) => u.id === props.selectedUserId);
    });

    const filteredUsers = computed(() => {
      const search = userFilter.value?.toLocaleLowerCase() ?? "";
      return props.users.filter((u: User) => {
        if (!search) return true;
        return (
          u.first_name.toLocaleLowerCase().includes(search) ||
          u.last_name.toLocaleLowerCase().includes(search) ||
          u.email.toLocaleLowerCase().includes(search)
        );
      });
    });

    const fullName = computed(() => {
      if (!user.value) return null;
      if (user.value?.profile?.full_name) return user.value?.profile?.full_name;
      return `${user.value.first_name
        .substring(0, 1)
        .toLocaleUpperCase()}${user.value.first_name
        .substring(1)
        .toLocaleLowerCase()}${user.value.last_name
        .substring(0, 1)
        .toLocaleUpperCase()}${user.value.last_name
        .substring(1)
        .toLocaleLowerCase()}`;
    });

    const avatar = computed(() => {
      if (!user.value) return;
      return getUserAvatarUrl(user.value);
    });

    const fallback = computed(() => {
      if (!user.value) return null;
      return `${user.value.first_name.substring(
        0,
        1
      )}${user.value.last_name.substring(0, 1)}`.toLocaleUpperCase();
    });

    return {
      ...useModalScroller(),
      userFilter,
      user,
      fullName,
      avatar,
      fallback,
      locale,
      filteredUsers,
      ...useI18n(),
    };
  },
});
</script>

<style scoped>
.list-title {
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary-shade);
}

.user-item {
  margin-bottom: var(--ion-padding);
}

.ion-content-scroll-host.scroller {
  padding-bottom: calc(var(--ion-padding) * 3);
}
</style>
