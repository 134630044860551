import { NotSavedYetError } from "@/classes/errors";
import { ApiClient } from "./clients";
import { Comment, ListService, AllOptions } from "@/types";
import { ticketUpdatedByMe } from "@/utils/socket";

export interface CommentListService extends ListService<Comment> {
  react?: (entity: Comment, emoji: string) => Promise<Comment>;
  togglePin?: (entity: Comment) => Promise<Comment>;
  unreact?: (reaction: any) => Promise<void>;
}

export const CommentsService: CommentListService = {
  async all(options?: AllOptions): Promise<Array<Comment>> {
    if (options?.ticket) {
      const comments = await ApiClient.get(
        `/api/v2/ticket/${options.ticket}/comments/`
      ).then((data) => {
        if (!data?.results?.length) return [];
        return data.results.map((c: any) => ({ ...c, ticket: options.ticket }));
      });
      return comments;
    } else if (options?.instance) {
      const comments = await ApiClient.get(
        `/api/v1/instance/${options.instance}/comments/`
      ).then((data) => {
        if (!data?.length || !Array.isArray(data)) return [];
        return data.map((c: any) => ({ ...c, instance: options.instance }));
      });
      return comments;
    }
    return Promise.reject("No ticket or instance given");
  },
  async add(entity: Comment): Promise<Comment> {
    if (typeof entity.ticket === "number") {
      const comment = await ApiClient.post(
        `/api/v1/ticket/${entity.ticket}/comment/`,
        {
          content: entity.content,
          mentioned_user_ids: entity.mentioned_user_ids,
          tagged_children: entity.tagged_children,
          parent: entity.parent,
        }
      );
      return { ...comment, ticket: entity.ticket, uuid: entity.uuid };
    } else if (typeof entity.instance === "number") {
      const comment = await ApiClient.post(
        `/api/v1/instance/${entity.instance}/comment/`,
        {
          content: entity.content,
          mentioned_user_ids: entity.mentioned_user_ids,
          tagged_children: entity.tagged_children,
          parent: entity.parent,
        }
      );
      return { ...comment, instance: entity.instance, uuid: entity.uuid };
    }
    return Promise.reject(new NotSavedYetError("Ticket not saved yet"));
  },
  async update(entity: Comment, columns: any): Promise<Comment> {
    if (typeof entity.ticket === "number") {
      const comment = await ApiClient.put(
        `/api/v1/ticket/${entity.ticket}/comment/${entity.id}/`,
        columns
      );
      return { ...comment, ticket: entity.ticket };
    } else if (typeof entity.instance === "number") {
      const comment = await ApiClient.put(
        `/api/v1/instance/${entity.instance}/comment/${entity.id}/`,
        columns
      );
      return { ...comment, instance: entity.instance };
    }
    return Promise.reject(new NotSavedYetError("Ticket not saved yet"));
  },
  async remove(entity: Comment) {
    if (entity.ticket) ticketUpdatedByMe(+entity.ticket);
    await ApiClient.delete(`/api/v1/comment/${entity.id}/`);
  },
  async togglePin(entity: Comment) {
    if (entity.ticket) ticketUpdatedByMe(+entity.ticket);
    const comment = await ApiClient.put(
      `/api/v1/comment/${entity.id}/togglePinned/`
    );
    if (typeof entity.ticket === "number") {
      return { ...comment, ticket: entity.ticket };
    } else if (typeof entity.instance === "number") {
      return { ...comment, instance: entity.instance };
    }
    return Promise.reject(new NotSavedYetError("Ticket not saved yet"));
  },
  async react(entity: Comment, emoji: string): Promise<Comment> {
    if (typeof entity.id === "number") {
      const reaction = await ApiClient.post(
        `/api/v1/comment/${entity.id}/reaction/`,
        { reaction: emoji }
      );
      return {
        ...entity,
        reactions: [...(entity.reactions || []), reaction],
        ticket: entity.ticket,
      };
    }
    return Promise.reject(new NotSavedYetError("Comment not saved yet"));
  },
  async unreact(entity: any): Promise<void> {
    await ApiClient.delete(`/api/v1/comment/reaction/${entity.id}/`);
  },
};
