import { Instance } from "@/types";
import { computed, Ref } from "vue";
import { parseISO, format } from "date-fns";
import { useFabriqStore } from "@/store/fabriq";
import { getDateFnsLocales } from "@/i18n";

/**
 * useDateString
 * @param instance an instance from the parent
 * @param dayOfMonthFormat the locale format for the day of the month
 * @returns a string with the date range, or only the start date
 */
export const useDateString = (
  instance: Instance,
  dayOfMonthFormat: string
): Ref<string> => {
  const fabriqStore = useFabriqStore();
  return computed(() => {
    if (!instance?.start_date || !instance?.end_date) return "";
    const startDate = format(parseISO(instance.start_date), dayOfMonthFormat, {
      locale: getDateFnsLocales(fabriqStore.locale),
    });
    const endDate = format(parseISO(instance.end_date), dayOfMonthFormat, {
      locale: getDateFnsLocales(fabriqStore.locale),
    });
    return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
  });
};
